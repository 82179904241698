import React from "react";
import { useState, useEffect } from 'react';
import Popup from "./Popup";

const Features = () => {

    const [isPopupVisible, setPopupVisible] = useState(false);

    // Automatically open the popup when the component mounts
    useEffect(() => {
        setPopupVisible(false);
    }, []);

    const closePopup = () => {
        setPopupVisible(false);
    };


    return (
        <div>

            {isPopupVisible && (
                <Popup imageUrl="Images/popup-image.jpeg" onClose={closePopup} />
            )}
            {/* loans */}
            <link
                rel="stylesheet"
                href="https://cdn.tailgrids.com/tailgrids-fallback.css"
            />

            <section class="pt-20 lg:pt-[120px] bg-[#F3F4F6]">
                <div class="container">
                    <div class="flex flex-wrap -mx-4">
                        <div class="w-full md:w-1/2 xl:w-1/4 px-4">
                            <div class="bg-white rounded-lg overflow-hidden mb-10">
                                <img
                                    src="Images/trail2.jpg"
                                    alt="image"
                                    class="w-full"
                                />
                                <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                                    <h3>
                                        <a
                                            href="javascript:void(0)"
                                            class="
                                                font-semibold
                                                text-dark text-xl
                                                sm:text-[22px]
                                                md:text-xl
                                                lg:text-[22px]
                                                xl:text-xl
                                                2xl:text-[22px]
                                                mb-4
                                                block
                                                hover:text-primary
                                                "
                                        >
                                            Gold Loan
                                        </a>
                                    </h3>


                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/4 px-4">
                            <div class="bg-white rounded-lg overflow-hidden mb-10">
                                <img
                                    src={"Images/homeloan3.jpg"}
                                    alt="image"
                                    class="w-full"
                                />
                                <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                                    <h3>
                                        <a
                                            href="javascript:void(0)"
                                            class="
                                                font-semibold
                                                text-dark text-xl
                                                sm:text-[22px]
                                                md:text-xl
                                                lg:text-[22px]
                                                xl:text-xl
                                                2xl:text-[22px]
                                                mb-4
                                                block
                                                hover:text-primary
                                                "
                                        >
                                            Home Loan
                                        </a>
                                    </h3>


                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/4 px-4">
                            <div class="bg-white rounded-lg overflow-hidden mb-10">
                                <img
                                    src={"Images/trail3.jpg"}
                                    alt="image"
                                    class="w-full"
                                />
                                <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                                    <h3>
                                        <a
                                            href="javascript:void(0)"
                                            class="
                                                font-semibold
                                                text-dark text-xl
                                                sm:text-[22px]
                                                md:text-xl
                                                lg:text-[22px]
                                                xl:text-xl
                                                2xl:text-[22px]
                                                mb-4
                                                block
                                                hover:text-primary
                                                "
                                        >
                                            Car Loan
                                        </a>
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/4 px-4">
                            <div class="bg-white rounded-lg overflow-hidden mb-10">
                                <img
                                    src="Images/trail4.jpg"
                                    alt="image"
                                    class="w-full"
                                />
                                <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                                    <h3>
                                        <a
                                            href="javascript:void(0)"
                                            class="
                                                font-semibold
                                                text-dark text-xl
                                                sm:text-[22px]
                                                md:text-xl
                                                lg:text-[22px]
                                                xl:text-xl
                                                2xl:text-[22px]
                                                mb-4
                                                block
                                                hover:text-primary
                                                "
                                        >
                                            Personal Loan
                                        </a>
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/* digc start*/}

            <section class="pt-10 lg:pt-[50px] bg-[#ffff]">
                <div class="container">
                    {/* <h2 className="md:text-4xl text-3xl text-blue-900 font-myFont text-center pb-10 font-bold"><a href="https ://www.dicgc.org.in">Manmandir bank is registered with DIGC</a></h2> */}
                    <div class="lg:grid  md:grid-cols-2 lg:mx-56 ">
                      
                            <div className="  p-10">
                                <img   src="Images/DIGCLOGO.jpg"  alt="image" class=" md:w-[200px] md:mx-auto " />
                                
                                <div class="">
                                    <h5 className=" text-center mt-4 font-bold" >    DIGC Logo   </h5>
                                    <p className="font-medium" >The Manmandir Co-op Bank Ltd; Vita  is registered with DIGC <a href="https://www.dicgc.org.in" className= " text-blue-500 hover:text-blue-700">(www.dicgc.org.in)</a></p>
                                </div>
                            </div>
                            <div className="  p-10">
                                <img   src="Images/DIGCQR.png"  alt="image" class=" w-[200px] mx-auto " />
                                
                                <div class="">
                                    <h5 className=" text-center mt-4 font-bold" >    DIGC QR-Code  </h5>
                                    <p className="font-medium text-center" > <a href="https://www.dicgc.org.in" className= "  text-blue-500 hover:text-blue-700">(www.dicgc.org.in)</a></p>
                                </div>
                            </div>
                          
                        
                    </div>
                </div>
            </section>

            {/* digc  end*/}
            <div className="">
                <section id="services" className="services section-bg mb-10">
                    <div
                        className="container  justify-center"
                        data-aos="fade-up"
                    >
                        <div class="section-title">
                            <h1 className="md:text-4xl text-3xl text-blue-900 font-myFont text-center py-8 font-bold">
                                Features
                            </h1>
                        </div>

                        <div className="row gy-4">
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch "
                                data-aos="zoom-in"
                                data-aos-delay="100"
                            >
                                <div className="icon-box  border border-success rounded-3 pt-1">
                                    <div className="icon mt-0 pt-0">
                                        <img
                                            src="https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-insurance-insurance-itim2101-lineal-itim2101-2.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Deposit Insurance Cover
                                        </a>
                                    </h4>
                                </div>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch "
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/000000/external-bank-taxes-flatart-icons-solid-flatarticons.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Core Banking Facility
                                        </a>
                                    </h4>

                                </div>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-wanicon-lineal-wanicon/64/000000/external-bull-market-stock-market-wanicon-lineal-wanicon.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            RTGS And NEFT Facility
                                        </a>
                                    </h4>
                                </div>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="100"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/dotty/80/000000/sms.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold px-5">
                                            SMS Facility
                                        </a>
                                    </h4>
                                </div>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-soft-fill-juicy-fish/80/000000/external-clearing-financial-services-soft-fill-soft-fill-juicy-fish.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold px-4 mr-5 ml-5">
                                            CTS Clearing
                                        </a>
                                    </h4>
                                </div>
                            </div>

                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/dotty/80/000000/data-protection.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Bank's Own Data Center DR Site
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-itim2101-lineal-itim2101/100/000000/external-credit-card-finance-itim2101-lineal-itim2101.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            APBS & DBTL Service ATM Card
                                            Facility
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-deposit-money-and-economy-itim2101-lineal-itim2101.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Pays monthly quarterly interest on
                                            the deposit
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/external-justicon-lineal-justicon/64/000000/external-income-ecommerce-justicon-lineal-justicon.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            1 % extra interest on Senior Citizen
                                            Deposits
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/ios/50/000000/discount--v1.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Attractive interest rates on deposit
                                            loans
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div className="icon-box border border-success rounded-3 pt-1">
                                    <div className="icon">
                                        <img
                                            src="https://img.icons8.com/ios/50/000000/baggage-lockers.png"
                                            alt=""
                                        />
                                    </div>
                                    <hr />
                                    <h4>
                                        <a href="" className="font-bold">
                                            Locker facility in all branches
                                        </a>
                                    </h4>
                                </div>
                            </div>
                            <div class="b-example-divider1"></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Features;
