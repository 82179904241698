import React, { useState } from 'react';

const Popup = ({ imageUrl, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
      <button onClick={onClose} className="close-button xs:py-2 px-3">
          Close
        </button>
        <img src={imageUrl} alt="Popup Image" className='lg:h-[600px] xs:h-auto '   style={{ maxWidth: '100%', }}/>
     
      </div>
    </div>
  );
};

export default Popup;
